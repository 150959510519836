<template>
  <div
    class="home"
    v-bind:class="$vuetify.breakpoint.smAndDown ? 'mx-2' : 'mx-0 px-0'"
    style="margin-bottom: 50px; margin-top: 80px"
  >
    <v-row no-gutters justify="center" class="mt-12 black--text">
      <v-col v-bind="main_card_width">
        <v-card color="transparent" class="pa-2">
          <v-card-text>
            <v-row class="rounded_row_white black--text pa-2 py-4 pb-12">
              <v-col cols="12" sm="4" v-if="!$vuetify.breakpoint.smAndDown">
                <v-img
                  class="service_image_position"
                  :src="require('@/assets/imgs/pages/cloud2.png')"
                  alt="Icona cloud"
                  contain
                  max-width="200"
                  :max-height="$vuetify.breakpoint.smAndDown ? 100 : ''"
                ></v-img>
              </v-col>
              <v-col cols="12" sm="7">
                <h1 class="service_title">
                  Il Cloud per il futuro della tua impresa.
                </h1>
                <div class="small_screen_service_image">
                  <v-img
                    v-if="$vuetify.breakpoint.smAndDown"
                    :src="require('@/assets/imgs/pages/cloud2.png')"
                    alt="Icona cloud"
                    class="mt-2 mb-6"
                    contain
                    max-width="200"
                    :max-height="$vuetify.breakpoint.smAndDown ? 100 : ''"
                  ></v-img>
                </div>
                <h2 class="service_subtitle">
                  Sfrutta il cloud e abbatti i costi!
                </h2>
                <p>
                  La tecnologia Cloud abbatte le barriere aziendali offrendo la
                  possibilità di accedere da qualsiasi dispositivo e da ovunque
                  a dati, servizi e software in esecuzione su server remoti
                  raggiungibili tramite Internet.
                </p>
                <p>
                  Con il Cloud le aziende sono sollevate dal dover gestire le
                  proprie infrastrutture dei server fisici e eseguire ed
                  aggiornare le applicazioni sulle proprie macchine.
                  <br />
                  Il grado di sicurezza e protezione dei dati in Cloud è
                  nettamente superiore rispetto a quanto le imprese possono
                  tipicamente attivare sulle proprie infrastrutture.
                </p>
                <p>
                  Il Cloud, con i suoi servizi, garantisce quindi alle imprese
                  un elevato risparmio economico, offrendo flessibilità,
                  scalabilità e riducendo il dispendio energetico.
                </p>
              </v-col>

                            <v-col cols="12" class="text-center">
                <contactCardButton
                  contact_type="cloud"
                  title="Vuoi valutare l'utlizzo del Cloud in azienda?"
                />
              </v-col>
              <!-- <v-col sm="7" offset-sm="4" cols="12" class="text-center">
                <v-btn
                  :to="{ name: 'Contacts', params: { topic: 'cloud' } }"
                  outlined
                  block
                >
                  Contattaci
                </v-btn>
              </v-col> -->

              <!-- <v-col cols="12" class="text-center">
                <v-card
                  small
                  text
                  color="white black--text"
                  :to="{ name: 'OurServicesAssistenzaConsulenza' }"
                  flat
                >
                  <v-card-actions>
                    <v-spacer />
                    Torna all'elenco dei servizi
                    <v-spacer />
                  </v-card-actions>
                </v-card>
              </v-col> -->
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import contactCardButton from "@/components/contactCardButton";

export default {
  components: {
    contactCardButton,
  },
  metaInfo: {
    title: "Soluzioni Cloud Based",
    meta: [
      {
        name: "description",
        content:
          "Scopri come le tecnologie cloud possono aiutarti ad abbattere i costi e permetterti di accedere ai tuoi dati sempre e ovunque.",
      },
      {
        name: "robots",
        content: "index",
      },
    ],
  },
};
</script>